import { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

/**
 * This is a workaround for an issue which breaks rendering on mobile on first load.
 */
const useReRenderOnMountKeyMobile = (keyName: string) => {
  const [isMounted, setIsMounted] = useState(`${keyName}_not_mounted`)

  useEffect(() => {
    setIsMounted(`${keyName}_mounted`)
  }, [keyName])

  if (isMobile) {
    return isMounted
  } else {
    return keyName
  }
}

export default useReRenderOnMountKeyMobile
