/** @jsxImportSource theme-ui */
import {FC, useState, useEffect, useRef} from 'react'
import _ from 'lodash'
import {EE} from 'src/utils/eventEmitter'
import {PrismicImageType} from 'root/graphql-types'
import {HeadingWithFilters} from 'src/components/organisms'
import {Grid} from 'src/components/atoms/layout'
import {ProjectCard} from 'src/components/organisms'
import {ml} from 'src/gatsby-plugin-theme-ui/utils'
import {motion, useAnimation} from 'framer-motion'
import SEO from 'src/components/organisms/SEO'
import {setActiveLink} from 'src/components/organisms/NavBar/NavBarDesktop'
import {Flex, useThemeUI} from 'theme-ui'
import UpArrowCircle from 'src/svg/up_arrow_circle.svg'
import useReRenderOnMountKeyMobile from 'src/utils/useReRenderOnMountKeyMobile'

import {
  OFFSET_CARDS_Y,
  STAGGER_TIME_CARDS,
  cardsAnimationY,
  cardsAnimationOpacity,
} from 'src/animations'

type ProjectProps = {
  pageContext: {
    seo_title: string
    seo_image_1200x1200: PrismicImageType
    seo_description: string
    works_listing_title: string
    flatServiceFilters: {uid: string; data: {name: string}}[]
    projectsByServices: any
    alternate_languages: any
    lang: string
    // TODO: enable if filters in German and English are needed
    // worksListingFilters: {
    //   uid: string
    //   name: string
    // }
  }
  path: string
}

const Project: FC<ProjectProps> = ({pageContext, path}) => {
  const {works_listing_title, flatServiceFilters, projectsByServices} =
    pageContext

  const [selectedService, setSelectedService] = useState(flatServiceFilters[0])
  const [projects, setProjects] = useState(
    projectsByServices[selectedService?.uid]
  )
  const [count, setCount] = useState(projects?.length || 0)
  const cardsControls = useAnimation()
  const cardAnimationState = useRef('INITIAL')
  const {
    theme: {rawColors},
  } = useThemeUI()

  useEffect(() => {
    const updateProjects = projectsByServices[selectedService?.uid]

    const maxDelay = STAGGER_TIME_CARDS * 3

    if (cardAnimationState.current === 'INITIAL') {
      setProjects(updateProjects)
      setCount(updateProjects?.length || 0)

      cardsControls.start((i: number) =>
        cardsAnimationY({i, y: '0px', delay: 1.25})
      )
      cardsControls.start((i: number) =>
        cardsAnimationOpacity({i, opacity: 1, delay: 1.25})
      )

      cardAnimationState.current = 'DONE'
      return
    }

    const removeCards = async () => {
      if (cardAnimationState.current !== 'DONE') return
      cardAnimationState.current = 'SWITCH'

      setCount(updateProjects?.length || 0)

      // HIDE PREVIOUS
      cardsControls.start((i: number) =>
        cardsAnimationY({i, y: OFFSET_CARDS_Y, maxDelay})
      )
      await cardsControls.start((i: number) =>
        cardsAnimationOpacity({i, opacity: 0, maxDelay})
      )

      setProjects(updateProjects)

      // SHOW NEXT
      cardsControls.start((i: number) =>
        cardsAnimationY({i, y: '0px', maxDelay})
      )
      await cardsControls.start((i: number) =>
        cardsAnimationOpacity({i, opacity: 1, maxDelay})
      )

      cardAnimationState.current = 'DONE'
    }
    removeCards()
  }, [selectedService, projectsByServices, cardsControls, projects?.length])

  setActiveLink('work')

  const reRenderOnMountKeyMobile = useReRenderOnMountKeyMobile('work')

  return (
    <div>
      <SEO
        title={pageContext?.seo_title}
        image={pageContext?.seo_image_1200x1200?.fluid?.src}
        description={pageContext?.seo_description}
        path={path}
        alternate_languages={pageContext.alternate_languages}
        lang={pageContext.lang}
      />
      <HeadingWithFilters
        titleTagName="h1"
        sx={{mt: ml('s', 'l')}}
        labels={flatServiceFilters}
        selectedLabel={selectedService}
        onClick={(filter) => {
          // when clicking on filters faster than the animation duration, then,
          // reset the animation state such that the count and projects can be
          // updated correctly
          if (cardAnimationState.current === 'SWITCH') {
            cardAnimationState.current = 'DONE'
          }
          setSelectedService(filter)
        }}
        title={works_listing_title}
        count={count}
      />
      <Grid columns={[1, 2, 3, 3, 3]}>
        {_.map(projects, (project, index) => {
          return (
            <motion.div
              initial={{y: OFFSET_CARDS_Y, opacity: 0}}
              animate={cardsControls}
              custom={index}
            >
              <ProjectCard
                key={index + reRenderOnMountKeyMobile}
                aspectRatio={3 / 4}
                imageDesktop={project?.data?.preview_image_desktop}
                imageMobile={project?.data?.preview_image_mobile}
                videoDesktop={project?.data?.previewVideoDesktop}
                videoMobile={project?.data?.previewVideoMobile}
                title={project.data.preview_title}
                client={project.data.client}
                slug={project.uid}
                useCursorRelativeToElementPosition={true}
                mb="l"
              />
            </motion.div>
          )
        })}
      </Grid>
      <Flex
        sx={{
          justifyContent: 'center',
          pb: ml('xl', 'xxl'),
          mb: ml(0, '-l'),
          mt: ml('0px', '20px'),
        }}
        onClick={() => EE.emit('smooth-scroll-set-scroll-animated', 0)}
      >
        <UpArrowCircle
          sx={{
            stroke: rawColors?.text,
            fill: rawColors?.background,
            cursor: 'pointer',
            ':hover': {
              stroke: rawColors?.background,
              fill: rawColors?.text,
            },
          }}
        />
      </Flex>
    </div>
  )
}

export default Project
